import { Viewport } from './viewport';
import * as THREE from 'three';
import { StaticAssets } from './viz_staticAssets';
import { View } from '_types';
import { PeopleAssets } from './viz_peopleAssets';
import { AnalysisAssets } from './viz_analysis';
import { store } from 'app/store';
import { setEyeTarget } from 'features/StationView/StationViewSlice';

export class CameraAssets {
    vpt: Viewport;
    scene: THREE.Group;
    static: StaticAssets;
    people: PeopleAssets;
    analysis: AnalysisAssets;

    viewPoints: Map<string, View>;

    private static CURRENT = 'current';
    private static GOAL = 'goal';

    api: {
        AddViewPoint: (name: string, viewpoint: View) => void;
        SetViewPoint: (name: string) => void;
        SetClosingRate: (fraction: number) => void;
        RefreshViewPoint: (name: string) => void;
    };

    closingRate = 0.1;
    small = 0.1;

    constructor(vpt: Viewport) {
        this.vpt = vpt;
        this.static = vpt.dependencies.get('static') as StaticAssets;
        this.people = vpt.dependencies.get('people') as PeopleAssets;
        this.analysis = vpt.dependencies.get('analysis') as AnalysisAssets;

        this.scene = new THREE.Group();
        this.scene.name = 'CameraAssets';

        this.viewPoints = new Map();
        this.viewPoints.set(CameraAssets.GOAL, {
            label: 'Goal',
            camera: {
                eye: this.vpt.camera.position.clone(),
                target: this.vpt.controls.target.clone(),
            },
        });
        this.viewPoints.set(CameraAssets.CURRENT, {
            label: 'current',
            camera: {
                eye: this.vpt.camera.position.clone(),
                target: this.vpt.controls.target.clone(),
            },
        });

        this.api = {
            AddViewPoint: this.AddViewPoint,
            SetViewPoint: this.SetViewPoint,
            SetClosingRate: this.SetClosingRate,
            RefreshViewPoint: this.RefreshViewPoint,
        };

        // finally, add the dynamic assets scene to the master scene
        this.vpt.scene.add(this.scene);

        // add Update to the vpt.updates array of callbacks to call
        this.vpt.updates.push(this.Update);
    }

    private Update = () => {
        store.dispatch(setEyeTarget({ eye: this.vpt.camera.position.clone(), target: this.vpt.controls.target.clone() }));
        this.UpdateCamera();        
        // console.log('[camera, target]: ', [this.vpt.camera.position, this.vpt.controls.target]);
    };

    /*=========================================
       Methods for handling camera movements
    =========================================*/

    private AddViewPoint = (name: string, viewpoint: View) => {
        if (name === CameraAssets.GOAL || name === CameraAssets.CURRENT) {
            console.error(
                `Cannot keys ${CameraAssets.GOAL} and ${CameraAssets.CURRENT} are reserved for CameraAssets class internal use.`
            );
        }

        this.viewPoints.set(name, viewpoint);
    };

    private SetViewPoint = (name: string) => {
        const viewpoint = this.viewPoints.get(name);
        if (viewpoint != undefined) {
            this.viewPoints.set(CameraAssets.CURRENT, {
                label: 'current',
                camera: {
                    eye: this.vpt.camera.position.clone(),
                    target: this.vpt.controls.target.clone(),
                },
            });

            const goal = this.viewPoints.get(CameraAssets.GOAL);
            goal?.camera.eye.copy(viewpoint.camera.eye);
            goal?.camera.target.copy(viewpoint.camera.target);

            this.static.SetAssetsVisibility(viewpoint.visibleAssets);
            this.static.trainAssets.SetTrainsAreVisible(!viewpoint.trainsAreNotVisible);
            this.static.arrowAssets.SetArrowVisibilty(viewpoint.visibleAssets);

            this.people.SetAxisYLimits(viewpoint.axisYLimits?.bottom, viewpoint.axisYLimits?.top);
            this.analysis.SetAxisYLimits(viewpoint.axisYLimits?.bottom, viewpoint.axisYLimits?.top);
        } else {
            console.error(`${name} does not exist in the Viewpoints Map.`);
        }
    };

    private RefreshViewPoint = (name: string) => {
        const viewpoint = this.viewPoints.get(name);
        if (!viewpoint) return;

        this.static.SetAssetsVisibility(viewpoint.visibleAssets);
        this.static.trainAssets.SetTrainsAreVisible(!viewpoint.trainsAreNotVisible);
        this.static.arrowAssets.SetArrowVisibilty(viewpoint.visibleAssets);

        this.people.SetAxisYLimits(viewpoint.axisYLimits?.bottom, viewpoint.axisYLimits?.top);
        this.analysis.SetAxisYLimits(viewpoint.axisYLimits?.bottom, viewpoint.axisYLimits?.top);
    };

    private UpdateCamera = () => {
        //get the goal from the goal position
        const current = this.viewPoints.get(CameraAssets.CURRENT);
        const goal = this.viewPoints.get(CameraAssets.GOAL);
        if (current == undefined || goal == undefined) {
            return;
        }

        //find the distance between current eye/target and goal eye/targets
        const eyeDelta = goal.camera.eye.clone().sub(current.camera.eye);
        const targetDelta = goal.camera.target.clone().sub(current.camera.target);

        //if equal (lengthSq==0), then do nothing.
        if (eyeDelta.lengthSq() == 0 && targetDelta.lengthSq() == 0) {
            return;
        }

        // if small, then set to be equal
        const isEyeSmall: boolean = eyeDelta.length() < this.small;
        const isTargetSmall: boolean = targetDelta.length() < this.small;
        //console.log(`eyeDelta: ${eyeDelta.length()} and targetDelta: ${targetDelta.length()} small= ${small} --> eye?${isEyeSmall} target?${isTargetSmall}`);
        if (isEyeSmall && isTargetSmall) {
            eyeDelta.set(0, 0, 0);
            targetDelta.set(0, 0, 0);

            current.camera.eye.copy(goal.camera.eye);
            current.camera.target.copy(goal.camera.target);
            this.viewPoints.set(CameraAssets.CURRENT, {
                label: 'current',
                camera: { eye: current.camera.eye.clone(), target: current.camera.target.clone() },
            });
        } else {
            //figure out how to move this.closingRate closer to goal
            eyeDelta.multiplyScalar(this.closingRate);
            targetDelta.multiplyScalar(this.closingRate);
            current.camera.eye = current.camera.eye.clone().add(eyeDelta);
            current.camera.target = current.camera.target.clone().add(targetDelta);
        }

        //update camera/controls with new values

        this.vpt.camera.position.copy(current.camera.eye);
        this.vpt.controls.target.copy(current.camera.target);
        this.vpt.camera.updateMatrix();
        this.vpt.controls.update();
    };

    private SetClosingRate = (fraction: number) => {
        this.closingRate = fraction;
    };
}
