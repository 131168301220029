import React from 'react';
import { EChartsCoreOption } from 'echarts';
import { ApolloError } from '@apollo/client';
import { graphColors, graphTextStyle, legendNames } from 'constants/constants';
import Graph from './Graph';
import { BarGraphData } from '_types';

interface Props {
    title: string;
    data: BarGraphData;
    dimensions: string[];
    xAxisOption?: Record<string, unknown> & { axisLabel?: Record<string, unknown> };
    yAxisOption?: Record<string, unknown> & { axisLabel?: Record<string, unknown> };
    seriesOption?: Record<string, unknown>;
    tooltipOption?: Record<string, unknown>;
    graphOption?: Record<string, unknown>;
    loading?: boolean;
    error?: ApolloError;
}

const BarGraph: React.FC<Props> = ({
    title,
    data,
    dimensions,
    xAxisOption,
    yAxisOption,
    seriesOption,
    tooltipOption,
    graphOption,
    loading,
    error,
}) => {
    const isError =!!error;

    const series = dimensions?.slice(1).map((name) =>
        name === 'average'
            ? {
                  type: 'line',
                  name: legendNames[name],
                  connectNulls: true,
                  ...seriesOption,
              }
            : {
                  type: 'bar',
                  name: legendNames[name],
                  connectNulls: true,
                  ...seriesOption,
              }
    );

    const option: EChartsCoreOption = {
        color: [...graphColors],
        legend: {
            top: 'top',
            left: 'right',
            textStyle: { ...graphTextStyle, fontSize: 14 },
        },
        xAxis: {
            type: 'category',
            ...xAxisOption,
            axisLabel: {
                ...graphTextStyle,
                ...xAxisOption?.axisLabel,
            },
        },
        yAxis: {
            ...yAxisOption,
            axisLabel: {
                ...graphTextStyle,
                ...yAxisOption?.axisLabel,
            },
        },
        dataset: {
            dimensions: dimensions,
            source: data,
        },
        series: series,
        tooltip: {
            trigger: false,
            ...tooltipOption,
        },
        ...graphOption,
    };

    return <Graph title={title} option={option} loading={loading} isError={isError} />;
};

export default BarGraph;
