import { useQuery } from '@apollo/client';
import { GETEQUIPMENTUSAGE } from '_queries';
import { EquipmentUsageType } from '_types';
import BarGraph from 'components/Graphs/BarGraph';
import React from 'react';

const EquipmentUsageChart: React.FC = () => {
    // const query = useQuery<EquipmentUsageType>(GETEQUIPMENTUSAGE);
    /* React.useEffect(() => {
        query.startPolling(2000);

        return () => query.stopPolling();
    }, []); */

    // const queriedData = query.data?.getEquipmentList[0].human;
    const [data, setData] = React.useState([]);

    // -------------- TO DO ------------------- //
    // Import this names form some API //

    const equipments = ['AA01', 'AA02', 'AA03', 'AA04', 'AE01', 'AE02'];

    // React.useEffect(() => {
    //     if (!queriedData) return;

    //     setData(queriedData.map((el) => [el.eq_name, el.count]));
    //     setData(
    //         equipments.map((equipName) => [
    //             equipName,
    //             queriedData.find((el) => el.eq_name === equipName)?.count ?? 0,
    //         ])
    //     );
    // }, [queriedData]);

    return (
        <BarGraph
            title="Equipment usage"
            data={data}
            dimensions={['Equipment usage']}
            xAxisOption={{
                axisLabel: {
                    rotate: 45,
                },
            }}
            tooltipOption={{
                trigger: 'item',
                formatter: (params) => params.data[0] + '</br>' + params.data[1],
            }}
            seriesOption={{
                label: { show: false },
            }}
            // loading={query.loading}
            // error={!!query.error}
        />
    );
};

export default EquipmentUsageChart;
