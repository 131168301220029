import React from 'react';
import { useLocation } from 'wouter';

import './metroLineOverview.scss';
import { useQuery } from '@apollo/client';
// import { GETWEATHER } from '_queries';
import { useAppDispatch } from 'app/hooks';
import { setMainSidebarFull } from 'features/StationView/StationViewSlice';
import { TrainData, WeatherData } from '_types/queries';
import List from 'components/List/List';
// import { TrainDataManager } from 'dataManagers/liveDatamanager/trainDataManager';
import { useLazyGetDigiTwinUIAPIQuery } from 'app/store';
import { MetroLine, MetroLinesList } from '_types';
import LoadingPage from 'components/LoadingPage/LoadingPage';
import Select, { SelectValue } from 'components/UIComponents/Select/Select';

const reqSvgs = require.context('../../assets/icons/weather-icons/WeatherSymbol3', false, /\.svg$/);
const iconPaths = reqSvgs
    .keys()
    .filter((s) => s[0] === '.')
    .reduce((o, s) => {
        o[s.replace('./', '').replace('.svg', '')] = reqSvgs(s);
        return o;
    }, {});

const MetroLineOverview: React.FC = () => {
    const [, setLocation] = useLocation();
    const dispatch = useAppDispatch();

    // CLOCK DATETIME
    const [currentDateTime, setCurrentDateTime] = React.useState(new Date());

    // TRAIN DATA
    // const tdm = new TrainDataManager("");
    // const [trainData, setTrainData] = React.useState<TrainData>();

    // WEATHER DATA
    // const weatherQuery = useQuery<WeatherData>(GETWEATHER);

    // PEOPLE ON PLATFORM DATA
    // const totalcountsInOutQuery = useQuery(GETTOTALCOUNTSINOUT);
    // const totalPassengers = totalcountsInOutQuery.data?.getTotalCountsInOut?.[0]
    //     ? totalcountsInOutQuery.data?.getTotalCountsInOut?.[0]?.incoming_people_count +
    //       totalcountsInOutQuery.data?.getTotalCountsInOut?.[0]?.outgoing_people_count
    //     : '--';

    // STATIONS FETCH
    const [triggerLazyGetDigiTwinAPI] = useLazyGetDigiTwinUIAPIQuery();
    const [metroLinesList, setMetroLinesList] = React.useState<MetroLinesList>([]);
    const [currentMetroLineSelectoption, setCurrentMetroLineSelectOption] =
        React.useState<SelectValue<string>>();

    // INITIAL  useEffect
    React.useEffect(() => {
        dispatch(setMainSidebarFull(true));

        // DATETIME POLL
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);

        // TRAIN DATA POLL
        // tdm.query.startPolling(1000);
        // const trainSubscriprion = tdm.query.subscribe({
        //     next: ({ data }) => {
        //         setTrainData(data);
        //     },
        // });

        // WEATHER, PEOPLE COUNT POLL
        // weatherQuery.startPolling(60000);
        // totalcountsInOutQuery.startPolling(500);

        // SET METROLINES DATA
        triggerLazyGetDigiTwinAPI('/groups')
            .unwrap()
            .then(({ site_groups }: { site_groups: MetroLinesList }) => {
                setCurrentMetroLineSelectOption({
                    value: site_groups[0]?.group_id,
                    label: site_groups[0]?.name,
                });
                setMetroLinesList(site_groups);
            });

        return () => {
            // CLEAR POLLINGS
            clearInterval(intervalId);
            // trainSubscriprion.unsubscribe();
            // weatherQuery.stopPolling();
            // totalcountsInOutQuery.stopPolling();
        };
    }, []);

    // METROLINES
    const [metroLines, setMetroLines] = React.useState<Record<string, MetroLine>>({});
    React.useEffect(() => {
        if (metroLinesList.length === 0) return;
        metroLinesList.forEach((metroLineData) =>
            triggerLazyGetDigiTwinAPI(`/groups/${metroLineData.group_id}`)
                .unwrap()
                .then(({ site_list }: { site_list: MetroLine }) => {
                    setMetroLines((s) => ({
                        ...s,
                        [metroLineData.group_id]: site_list,
                    }));
                })
        );
    }, [metroLinesList]);

    return Object.keys(metroLines).length === 0 ? (
        <LoadingPage />
    ) : (
        <div id="metroLineOverview">
            <main>
                {/* TODO: get this title dependant on the customer */}
                {/* <div id="title">
                    <div id="metro-sign-container">
                        <div id="metro-sign">
                            <span>M</span>
                        </div>
                        <div id="text">
                            <h2>Helsinki</h2>
                            <h2>Metrolinja</h2>
                        </div>
                    </div>
                    <div id="date-time">
                        <span> {currentDateTime.toLocaleTimeString()} </span>
                        <span>{currentDateTime.toLocaleDateString('en-GB')}</span>
                    </div>
                </div> */}
                <div className="select-metro-line-container">
                    <span>Select a metro line</span>
                    <Select
                        placeholder="Select a metro line"
                        value={currentMetroLineSelectoption}
                        onChange={(e) => setCurrentMetroLineSelectOption(e as SelectValue<string>)}
                        options={metroLinesList.map((metroLineData) => ({
                            value: metroLineData.group_id,
                            label: metroLineData.name,
                        }))}
                    />
                </div>
                {currentMetroLineSelectoption?.value &&
                metroLines[currentMetroLineSelectoption?.value] ? (
                    <List
                        head={
                            <tr>
                                <td>STATION</td>
                            </tr>
                        }>
                        {metroLines[currentMetroLineSelectoption?.value ?? ''].map((station) => (
                            <tr key={station.site_id}>
                                <td>
                                    <div
                                        className={`station-name ${
                                            station.is_active ? 'active' : ''
                                        }`}
                                        onClick={() => {
                                            if (!station.is_active) return;
                                            setLocation(`/stations/${station.site_id}`);
                                        }}>
                                        {station.site_name}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </List>
                ) : (
                    <LoadingPage />
                )}
            </main>
        </div>
    );
};

export default MetroLineOverview;
