import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
    FetchBaseQueryMeta,
    QueryDefinition,
} from '@reduxjs/toolkit/query/react';
import AuthReducer from 'features/auth/AuthSlice';
import StationViewReducer from 'features/StationView/StationViewSlice';
import SimulationViewReducer from 'features/StationView/SimulationSlice';
import AlertSlice from 'features/StationView/AlertSlice';
import { UseQueryHookResult } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import MetroLinesSliceReducer from 'features/metroLines/metroLines';
import { QueryActionCreatorResult } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import DashboardSliceReducer  from 'features/StationView/DashboardSlice';

export const simulationAPI = createApi({
    reducerPath: 'simulationAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_SIMULATION_API_URL}`,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.accessToken;

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getSimulationAPI: builder.query({
            query: (path: string) => path,
        }),
    }),
});
export const { useGetSimulationAPIQuery } = simulationAPI;

export const digiTwinUIAPI = createApi({
    reducerPath: 'digiTwinAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_DIGI_TWIN_UI_API}`,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.accessToken;

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getDigiTwinUIAPI: builder.query({
            query: (path) => path,
        }),
    }),
});
export const { useGetDigiTwinUIAPIQuery, useLazyGetDigiTwinUIAPIQuery } = digiTwinUIAPI;
export type DigiTwinUIAPIQueryDefinition<T> = QueryDefinition<
    unknown,
    BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError, unknown, FetchBaseQueryMeta>,
    never,
    T,
    'digiTwinAPI'
>;
export type DigiTwinUIAPIQueryHookResult<T> = UseQueryHookResult<DigiTwinUIAPIQueryDefinition<T>>;
export type DigiTwinUIAPIQueryActionCreatorResult<T> = QueryActionCreatorResult<
    DigiTwinUIAPIQueryDefinition<T>
>;

export const store = configureStore({
    reducer: {
        auth: AuthReducer,
        stationView: StationViewReducer,
        simulationSlice: SimulationViewReducer,
        alertsSlice: AlertSlice,
        metroLineSlice: MetroLinesSliceReducer,
        dashboardSlice: DashboardSliceReducer,
        [simulationAPI.reducerPath]: simulationAPI.reducer,
        [digiTwinUIAPI.reducerPath]: digiTwinUIAPI.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        })
            .concat(simulationAPI.middleware)
            .concat(digiTwinUIAPI.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
