import { gql } from '@apollo/client';

export const GETPEOPLECOUTDATABYMONTH = gql`
    query MyQuery {
        MontlyDataPeople {
            count
            event_date
        }
    }
`;

export const GETPEOPLEANDBIKECOUNTDATAWEEKLY = gql`
    query MyQuery {
        weeklyDatapeople {
            human {
                this_week_data {
                    Monday
                    Tuesday
                    Wednesday
                    Thursday
                    Friday
                    Saturday
                    Sunday
                }
                weekly_avg_data {
                    Monday
                    Tuesday
                    Wednesday
                    Thursday
                    Friday
                    Saturday
                    Sunday
                }
            }
        }
    }
`;

export const GETPEOPLECOUTDATADAILYBYHOUR = gql`
    query MyQuery {
        getPeopleCountDataRDS {
            avg_last_3_days
            today_data
        }
    }
`;

export const GETMETROZONESDATA = gql`
    query MyQuery {
        getMetroZoneData {
            Emetro {
                Emetro_zone_A1
                Emetro_zone_A2
                Emetro_zone_A3
                Emetro_zone_B1
                Emetro_zone_B2
                Emetro_zone_B3
                Emetro_zone_C1
                Emetro_zone_C2
                Emetro_zone_C3
                Emetro_zone_D1
                Emetro_zone_D2
                Emetro_zone_D3
            }
            Wmetro {
                Wmetro_zone_A1
                Wmetro_zone_A2
                Wmetro_zone_A3
                Wmetro_zone_B1
                Wmetro_zone_B2
                Wmetro_zone_B3
                Wmetro_zone_C1
                Wmetro_zone_C2
                Wmetro_zone_C3
                Wmetro_zone_D1
                Wmetro_zone_D2
                Wmetro_zone_D3
            }
        }
    }
`;

export const GETPEOPLECOUTDATAMONTHLY = gql`
    query MyQuery {
        getPeopleCountDataRDSMonthly {
            count
            eq_name
            eq_type
            event_hour
            event_time
            id
            weekday
            line_name
            reason
            sensor_id
            traffic_types
            event_date
        }
    }
`;

export const GETPEOPLEONPLATFORMTREND = gql`
    query MyQuery($site_id: String) {
        getPeopleOnPlatformTrend(site_id: $site_id) {
            plat_count
            site_id
            timestamp
            ttl_time
        }
    }
`;

export const GETINOUTCOUNTWEEKLY = gql`
    query MyQuery($site_id: String) {
        getInOutCountWeeklyChart(site_id: $site_id) {
            in_counts
            local_date
            out_counts
            site_id
            weekday
        }
    }
`;

export const GETINOUTCOUNTHOURLY = gql`
    query MyQuery($site_id: String) {
        getInOutCountHourlyChart(site_id: $site_id) {
            hour
            in_counts
            out_counts
            local_time
            site_id
            ttl_time
            weekday
        }
    }
`;

export const GETMETROUSAGEHISTORY = gql`
    query MyQuery($site_id: String) {
        getMetroUsageChart(site_id: $site_id) {
            car
            door_count
            door_name
            metro_side
            metro_side_door
            site_id
            ttl_time
        }
    }
`;

export const GETMETROUSAGEDAILY = gql`
    query MyQuery($site_id: String) {
        getDailyMetroUsageChart(site_id: $site_id) {
            car
            date
            door_name
            door_count
            metro_side
            metro_side_door
            site_id
            ttl_time
        }
    }
`;

export const GETINOUTCOUNTWEEKLYAVERAGE = gql`
    query MyQuery($site_id: String) {
        getInOutAverageDailyLine(site_id: $site_id) {
            site_id
            total_counts
            weekday
        }
    }
`;

export const GETINOUTCOUNTHOURLYAVERAGE = gql`
    query MyQuery($site_id: String) {
        getInOutAverageHourlyLine(site_id: $site_id) {
            hour
            site_id
            weekday
            total_counts
        }
    }
`;
